/*
 * See: https://github.com/marcusmolchany/react-jazzicon/blob/master/src/Jazzicon.tsx
 */
import MersenneTwister from 'mersenne-twister';
import cx from 'classnames';

import styles from './Jazzicon.module.scss';

const diameter = 20;
const shapeCount = 4;

type Props = {
  colorCount: number;
  seed: number;
  size: 'tiny' | 'small' | 'large' | 'huge';
  overlayText?: string;
  containerClassName?: string;
  overlayClassName?: string;
  iconClassName?: string;
};

export function Jazzicon({
  colorCount,
  seed,
  overlayText,
  containerClassName,
  overlayClassName,
  iconClassName,
  size,
}: Props) {
  const generator = new MersenneTwister(seed);
  const mutableColorArray = Array.from({ length: colorCount }).map((_, index) => `var(--io-jazz-color-${index + 1})`);
  const shapesArr = Array.from({ length: shapeCount }).fill(null);

  const shapes = shapesArr.map((_s, i) =>
    genShape({ colors: mutableColorArray, diameter, i, total: shapeCount - 1, generator }),
  );

  /*
   * We do this last so that it does not affect the original Jazzicon implementation
   */
  const bg = genColor({ colors: mutableColorArray, generator });

  return (
    <span className={cx(styles.container, styles[size], containerClassName)}>
      <svg
        className={cx(styles.svg, iconClassName)}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox={`0 0 ${diameter} ${diameter}`}
        aria-hidden
        style={{ background: bg }}
      >
        {shapes}
      </svg>
      {overlayText ? <span className={cx(styles.overlay, overlayClassName)}>{overlayText}</span> : null}
    </span>
  );
}

function genShape({
  diameter,
  i,
  colors,
  total,
  generator,
}: {
  colors: string[];
  diameter: number;
  i: number;
  total: number;
  generator: MersenneTwister;
}) {
  const center = diameter / 2;
  const firstRot = generator.random();
  const angle = Math.PI * 2 * firstRot;
  const velocity = (diameter / total) * generator.random() + (i * diameter) / total;
  const tx = Math.cos(angle) * velocity;
  const ty = Math.sin(angle) * velocity;
  const translate = 'translate(' + tx + ' ' + ty + ')';

  // Third random is a shape rotation on top of all of that.
  const secondRot = generator.random();
  const rot = firstRot * 360 + secondRot * 180;
  const rotate = 'rotate(' + rot.toFixed(1) + ' ' + center + ' ' + center + ')';
  const transform = translate + ' ' + rotate;
  const fill = genColor({ colors, generator });

  return (
    <rect
      key={i}
      x="0"
      y="0"
      rx="0"
      ry="0"
      height={diameter}
      width={diameter}
      transform={transform}
      fill={fill} // todo: make prop
    />
  );
}

function genColor({ colors, generator }: { colors: string[]; generator: MersenneTwister }): string {
  generator.random(); // purposefully call the generator once, before using it again on the next line
  const idx = Math.floor(colors.length * generator.random());
  const color = colors.splice(idx, 1)[0];
  return color;
}
