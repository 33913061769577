import cx from 'classnames';

import { Jazzicon } from './Jazzicon';
import { getSeedFromAnything } from './getSeedFromAnything';
import styles from './CryptoJazzicon.module.scss';

type Props = {
  address: string;
  size: React.ComponentPropsWithoutRef<typeof Jazzicon>['size'];
  className?: string;
};

export function CryptoJazzicon({ address, size, className }: Props) {
  const seed = getSeedFromAnything({ value: address });
  return <Jazzicon colorCount={7} seed={seed} size={size} containerClassName={cx(styles.container, className)} />;
}
