import React from 'react';
import classNames from 'classnames';

import styles from './Heading.module.scss';

export type HeadingVariants =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'subheading1'
  | 'subheading2'
  | 'section';

type Props = React.ComponentPropsWithoutRef<keyof JSX.IntrinsicElements> & {
  className?: string;
  as: React.ElementType | string;
  variant: HeadingVariants;
};

export function Heading({ as, variant, className, ...rest }: Props) {
  const Element = as ?? 'div';
  const classes = classNames(styles[variant], className);

  return <Element className={classes} {...rest} />;
}
