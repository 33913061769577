import React from 'react';
import type { ResultOf } from '@graphql-typed-document-node/core';

import { Avatar, AvatarProps } from '@/lib/io-kit/Avatar';
import { FragmentType, graphql, getFragmentData } from '@/lib/gql';
import type { UserInfo } from '@/lib/auth';
import { UserRole, UserType } from '@/lib/gql/graphql';

type UserParam = UserInfo | FragmentType<typeof UserAvatarFragment>;
type UserParamInternal = UserInfo | ResultOf<typeof UserAvatarFragment>;
type SizeProp = React.ComponentPropsWithoutRef<typeof Avatar>['size'];

type Props = {
  size: SizeProp;
  user: UserParam;
  highlight?: AvatarProps['highlight'];
};

export const UserAvatarFragment = graphql(`
  fragment UserAvatar on User {
    __typename
    id
    details {
      name
      initials
      profilePictureUri
      role
      type
    }
  }
`);

export function UserAvatar({ user: userParam, size, highlight = null }: Props) {
  if (isUserFragment(userParam)) {
    const user = getFragmentData(UserAvatarFragment, userParam);
    return (
      <Avatar
        size={size}
        src={user.details.profilePictureUri}
        userId={user.id}
        userName={user.details.name}
        userInitials={user.details.initials}
        userRole={getUserRole(user)}
        userType={getUserType(user)}
        highlight={highlight}
      />
    );
  }

  const user = userParam;
  return (
    <Avatar
      size={size}
      src={user.picture}
      userId={user.id}
      userName={user.name}
      userInitials={user.initials}
      userRole={getUserRole(user)}
      userType={getUserType(user)}
      highlight={highlight}
    />
  );
}

function isUserFragment(value: UserParam): value is FragmentType<typeof UserAvatarFragment> {
  return !!(value as any).__typename;
}

function isUserFragmentInfo(value: UserParamInternal): value is ResultOf<typeof UserAvatarFragment> {
  return !!(value as any).__typename;
}

export function getUserRole(user: UserParamInternal): React.ComponentPropsWithoutRef<typeof Avatar>['userRole'] {
  const role = isUserFragmentInfo(user) ? user.details.role : user.role;
  if (role === UserRole.Admin || role === 'admin') {
    return 'admin';
  }

  return 'user';
}

export function getUserType(user: UserParamInternal): React.ComponentPropsWithoutRef<typeof Avatar>['userType'] {
  if (!isUserFragmentInfo(user)) {
    // Why would an API client use the Dashboard UI?
    return 'human';
  }

  if (user.details.type === UserType.Client) {
    return 'api';
  }

  return 'human';
}
