'use client';
import React, { useEffect, useRef } from 'react';

import { IconName, Icons, isIconName } from '@/lib/io-kit/Icons';
import { IconAsset, IconAssetName } from '@/lib/io-kit/IconAssets';
import { ModalDialog, ModalDialogProps } from '@/components/ModalDialog';

import styles from './OperationModal.module.scss';

type Props = Omit<ModalDialogProps, 'children'> & {
  isOpen: boolean;
  onClose: () => void;
  largeIcon?: IconName | IconAssetName;
  iconLabel?: string;
  heading: React.ReactNode;
  description: React.ReactNode;
  footer: React.ReactNode;
  children?: React.ReactNode;
  'data-testid'?: string;
};

export function OperationModal(props: Props) {
  const { isOpen, onClose, largeIcon, iconLabel, heading, description, children = null, footer, ...rest } = props;

  // Ensure the modal is closed when the component is unmounted
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  useEffect(() => {
    return () => onCloseRef.current?.();
  }, []);

  let LargeIcon;
  if (largeIcon !== undefined) {
    LargeIcon = isIconName(largeIcon) ? Icons[largeIcon] : IconAsset[largeIcon];
  }

  return (
    <ModalDialog isOpen={isOpen} footer={footer} onClose={onClose} {...rest}>
      {(LargeIcon || iconLabel) && (
        <div className={styles.hero}>
          {LargeIcon && <LargeIcon className={styles.heroIcon} />}
          {iconLabel && <span className={styles.heroLabel}>{iconLabel}</span>}
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.description}>{description}</div>
        {children}
      </div>
    </ModalDialog>
  );
}
