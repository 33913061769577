'use client';
import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import cx from 'classnames';

import styles from './inputs.module.scss';

type Sizes = 'medium' | 'large';

type BaseProps = {
  disabled?: boolean;
  error?: boolean;
  prefix?: string;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  hidden?: boolean;
  suffix?: string;
};

type InputProps = BaseProps &
  InputHTMLAttributes<HTMLInputElement> & {
    // Cannot call it 'size' because it conflicts with the input attribute
    sizeVariant?: Sizes;
  };

export const Input = forwardRef<HTMLInputElement, InputProps>(function InputWithRef(props, ref) {
  const { className, disabled, sizeVariant = 'medium', iconBefore, iconAfter, error, hidden, ...rest } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  // Set up an internal ref and export it to the forwarded ref
  useImperativeHandle(ref, () => inputRef.current as unknown as HTMLInputElement, []);

  const inputClasses = cx(styles.input, className);

  return (
    <InnerWrapper
      sizeVariant={sizeVariant}
      inputRef={inputRef}
      iconBefore={iconBefore}
      iconAfter={iconAfter}
      error={error}
      hidden={hidden}
      {...props}
    >
      <input ref={inputRef} className={inputClasses} disabled={disabled} {...rest} />
    </InnerWrapper>
  );
});

type TextAreaProps = BaseProps &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    // Cannot call it 'size' because it conflicts with the input attribute
    sizeVariant?: Sizes;
  };

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextAreaWithRef(props, ref) {
  const { className, disabled, sizeVariant = 'medium', ...rest } = props;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  // Set up an internal ref and export it to the forwarded ref
  useImperativeHandle(ref, () => textAreaRef.current as unknown as HTMLTextAreaElement, []);

  const textareaClasses = cx(styles.textarea, className);

  return (
    <InnerWrapper sizeVariant={sizeVariant} inputRef={textAreaRef} {...props}>
      <textarea ref={textAreaRef} className={textareaClasses} disabled={disabled} {...rest} />
    </InnerWrapper>
  );
});

type InnerWrapperProps = BaseProps & {
  sizeVariant: Sizes;
  children: React.ReactNode;
  inputRef: React.RefObject<HTMLInputElement> | React.RefObject<HTMLTextAreaElement> | null;
  onMouseDown?: React.MouseEventHandler<HTMLElement>;
};
function InnerWrapper({
  children,
  prefix,
  iconBefore,
  iconAfter,
  inputRef,
  onMouseDown,
  error,
  disabled,
  sizeVariant,
  hidden,
  suffix,
}: InnerWrapperProps) {
  // Always focus the input when clicking on the container.
  // Needed because pressing on the items other than the input would not trigger a focus event.
  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const target: HTMLElement = event.target as HTMLElement;
      // Running e.preventDefault() on the INPUT prevents double click behaviour
      if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA') {
        event.preventDefault();
      }

      if (inputRef && !disabled && window?.document.activeElement !== inputRef.current) {
        inputRef.current?.focus();
      }

      onMouseDown?.(event);
    },
    [disabled, inputRef, onMouseDown],
  );

  const wrapperClasses = cx(styles.inputWrapper, styles[sizeVariant], {
    [styles.hasError]: error,
    [styles.disabled]: disabled,
    [styles.hidden]: hidden,
  });

  const iconBeforeClasses = cx(styles.iconBefore, {
    [styles.iconBeforeMedium]: sizeVariant === 'medium',
    [styles.iconBeforeLarge]: sizeVariant === 'large',
  });

  const iconAfterClasses = cx(styles.iconAfter, {
    [styles.iconAfterMedium]: sizeVariant === 'medium',
    [styles.iconAfterLarge]: sizeVariant === 'large',
  });

  return (
    <div className={wrapperClasses} onMouseDown={handleMouseDown}>
      {iconBefore ? <div className={iconBeforeClasses}>{iconBefore}</div> : null}
      {prefix ? <div className={styles.prefix}>{prefix}</div> : null}

      {children}

      {suffix ? <div className={styles.suffix}>{suffix}</div> : null}

      {iconAfter ? <div className={iconAfterClasses}>{iconAfter}</div> : null}
    </div>
  );
}
