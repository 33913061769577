import { Card, CardProps, CardSection, CardDivider } from './Card';
import { ValuesList } from './ValuesList';

type Subcomponents = {
  Section: typeof CardSection;
  Divider: typeof CardDivider;
  ValuesList: typeof ValuesList;
};

const DetailsCardComposed: React.FC<CardProps> & Subcomponents = ({ children, ...rest }: CardProps) => (
  <Card {...rest}>{children}</Card>
);
DetailsCardComposed.Section = CardSection;
DetailsCardComposed.Divider = CardDivider;
DetailsCardComposed.ValuesList = ValuesList;

export const DetailsCard = DetailsCardComposed;
