import React, { BaseHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './FormGroup.module.scss';

type Props = BaseHTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

export function FormGroup({ className, children, ...props }: Props) {
  const formGroupClasses = classNames(styles.formGroup, className);

  return (
    <div className={formGroupClasses} {...props}>
      {children}
    </div>
  );
}
