import React, { HTMLAttributes, LabelHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './labels.module.scss';

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  disabled?: boolean;
  error?: boolean;
};
export function Label({ className, disabled = false, error = false, ...props }: LabelProps) {
  const labelClasses = classNames(styles.label, { [styles.disabled]: disabled, [styles.hasError]: error }, className);

  return <label className={labelClasses} {...props} />;
}

type ComplementartyTextProps = {
  leadingIcon?: React.ReactNode;
  disabled?: boolean;
};

type HelperProps = HTMLAttributes<HTMLDivElement> & ComplementartyTextProps;
export function HelperText({ className, disabled = false, leadingIcon, children, ...props }: HelperProps) {
  const wrapperClasses = classNames(styles.helperText, { [styles.disabled]: disabled }, className);

  return (
    <div className={wrapperClasses} {...props}>
      {leadingIcon ? <span className={styles.complementaryTextLeadingIcon}>{leadingIcon}</span> : null}

      {children}
    </div>
  );
}

type ErrorProps = HTMLAttributes<HTMLDivElement> & ComplementartyTextProps;
export function ErrorText({ className, disabled = false, children, leadingIcon, ...props }: ErrorProps) {
  const wrapperClasses = classNames(styles.errorText, { [styles.disabled]: disabled }, className);

  return (
    <div className={wrapperClasses} {...props}>
      {leadingIcon ? <span className={styles.complementaryTextLeadingIcon}>{leadingIcon}</span> : null}

      {children}
    </div>
  );
}
