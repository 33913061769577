'use client';
import { ReactNode } from 'react';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import cx from 'classnames';

import { IconName, Icons, isIconName } from '../Icons';
import { IconAsset, IconAssetName, isIconAssetName } from '../IconAssets';

import styles from './RadioButton.module.scss';

type RadioItem = {
  value: string;
  label: ReactNode;
  helperText?: string;
  icon?: IconName | IconAssetName;
};

type RadioButtonProps = RadixRadioGroup.RadioGroupItemProps & {
  position?: 'left' | 'right';
} & RadioItem;

export function RadioButton({ value, label, helperText, icon, position = 'left', disabled }: RadioButtonProps) {
  return (
    <div className={styles.radioGroupItemContainer} key={value}>
      {position === 'left' && (
        <LabeledInfo helperText={helperText} icon={icon} htmlFor={`radio-${value}`}>
          {label}
        </LabeledInfo>
      )}
      <RadixRadioGroup.Item
        className={cx(styles.radioGroupItem, {
          [styles.disabled]: disabled,
        })}
        id={`radio-${value}`}
        disabled={disabled}
        value={value}
      >
        <RadixRadioGroup.Indicator className={styles.radioGroupIndicator} />
      </RadixRadioGroup.Item>

      {position === 'right' && (
        <LabeledInfo helperText={helperText} icon={icon}>
          {label}
        </LabeledInfo>
      )}
    </div>
  );
}

function LabeledInfo({
  helperText,
  children,
  icon,
  htmlFor,
}: {
  children: ReactNode;
  helperText?: string;
  icon?: IconName | IconAssetName;
  htmlFor?: string;
}) {
  let IconComponent = null;
  if (isIconName(icon)) {
    IconComponent = Icons[icon];
  } else if (isIconAssetName(icon)) {
    IconComponent = IconAsset[icon];
  }

  return (
    <label className={styles.labeledInfoContainer} htmlFor={htmlFor}>
      <div>{IconComponent ? <IconComponent width={50} height={50} /> : null}</div>
      <div className={styles.labelWithIcon}>
        {children}
        {helperText && <div className={styles.helperText}>{helperText}</div>}
      </div>
    </label>
  );
}
