import cx from 'classnames';

import styles from './Card.module.scss';

export type CardProps = React.ComponentPropsWithoutRef<'div'> & {
  children: React.ReactNode;
  variant?: 'raised' | 'flat';
};

export function Card({ children, variant = 'raised', className, ...rest }: CardProps) {
  return (
    <div className={cx(styles.card, styles[variant], className)} {...rest}>
      {children}
    </div>
  );
}

type CardSectionProps = React.ComponentPropsWithoutRef<'div'> & {
  children: React.ReactNode;
  heading?: React.ReactNode;
};

export function CardSection({ children, heading, className, ...rest }: CardSectionProps) {
  return (
    <div className={cx(styles.section, className)} {...rest}>
      {heading ? <div className={styles.sectionHeading}>{heading}</div> : null}

      {children}
    </div>
  );
}

export function CardDivider({
  className,
  variant = 'solid',
  ...rest
}: React.ComponentPropsWithoutRef<'div'> & {
  variant?: 'solid' | 'dotted' | 'dashed';
}) {
  return <div className={cx(styles.divider, styles[variant], className)} {...rest}></div>;
}
