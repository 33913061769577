'use client';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import React from 'react';
import cx from 'classnames';

import styles from './radioGroup.module.scss';

type RadioGroupProps = RadixRadioGroup.RadioGroupProps & {
  dataTestId?: string;
  orientation?: 'horizontal' | 'vertical';
  error?: boolean;
  position?: 'left' | 'right';
  children: React.ReactNode;
};

export function RadioGroup({
  className,
  dataTestId = 'radio-group',
  defaultValue,
  disabled,
  error = false,
  orientation = 'vertical',
  position = 'left',
  onValueChange,
  children,
  ...props
}: RadioGroupProps) {
  return (
    <RadixRadioGroup.Root
      {...props}
      data-testid={dataTestId}
      orientation={orientation}
      className={cx(
        styles.radioGroupRoot,
        orientation === 'horizontal' ? styles.horizontal : styles.vertical,
        className,
      )}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      required
      disabled={disabled}
    >
      {children}
    </RadixRadioGroup.Root>
  );
}
