import cx from 'classnames';

import { Jazzicon } from './Jazzicon';
import { getSeedFromAnything } from './getSeedFromAnything';
import styles from './VaultJazzicon.module.scss';

type Props = {
  vaultId: string;
  threshold?: number;
  className?: string;
  size: React.ComponentPropsWithoutRef<typeof Jazzicon>['size'];
};

export function VaultJazzicon({ vaultId, threshold, className, size }: Props) {
  const seed = getSeedFromAnything({ value: vaultId });
  return (
    <Jazzicon
      colorCount={5}
      seed={seed}
      size={size}
      overlayText={String(threshold)}
      containerClassName={cx(styles.container, className)}
    />
  );
}
