'use client';
import * as BaseTooltip from '@radix-ui/react-tooltip';

import styles from './Tooltip.module.scss';

const TOOLTIP_DELAY = 300;

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  side?: BaseTooltip.TooltipContentProps['side'];
  align?: BaseTooltip.TooltipContentProps['align'];
};

export function Tooltip({ children, content, side, align }: Props) {
  return (
    <BaseTooltip.Provider delayDuration={TOOLTIP_DELAY}>
      <BaseTooltip.Root>
        <BaseTooltip.Trigger asChild>
          <span className={styles.triggerWrapper}>{children}</span>
        </BaseTooltip.Trigger>

        <BaseTooltip.Portal>
          <BaseTooltip.Content className={styles.content} sideOffset={5} side={side} align={align}>
            {content}
            <BaseTooltip.Arrow className={styles.arrow} />
          </BaseTooltip.Content>
        </BaseTooltip.Portal>
      </BaseTooltip.Root>
    </BaseTooltip.Provider>
  );
}
