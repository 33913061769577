import cx from 'classnames';

import styles from './ValuesList.module.scss';

type DataItem = { label: React.ReactNode; value: React.ReactNode; align?: 'start' | 'baseline' | 'center' };

export function ValuesList({ data }: { data: ReadonlyArray<undefined | null | DataItem> }) {
  return (
    <div role="grid" className={styles.table}>
      <div role="rowgroup">
        {data
          .filter((it): it is DataItem => !!it)
          .map((it, index) => {
            return (
              <div role="row" key={index} className={cx(styles.row, styles[it.align ?? 'start'])}>
                <div role="rowheader" className={styles.labelCell}>
                  {it.label}
                </div>
                <div role="gridcell" className={styles.valueCell}>
                  {it.value}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
