import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';

export function getSeedFromAnything({ value }: { value: string }) {
  if (value.startsWith('0x')) {
    return Number.parseInt(value.slice(2, 10), 16);
  }

  const hash = Hex.stringify(sha256(value));
  return Number.parseInt(hash.slice(0, 8), 16);
}
