'use client';
import { useEffect } from 'react';

import { useSubscriptionsRerendersWatcher } from '@/lib/subscriptions';

/**
 * This component hooks the loading sections and pages to disable rerenders triggered by subscriptions
 * when a page or section is loading. This is done to prevent the page from being stuck in a loading state.
 */
export function HaltResumeRerenders() {
  const { addRerenderLock, removeRerenderLock, rerenderLocks } = useSubscriptionsRerendersWatcher();

  useEffect(() => {
    addRerenderLock();
    return () => {
      removeRerenderLock();
    };
  }, [addRerenderLock, removeRerenderLock, rerenderLocks]);

  return null;
}
